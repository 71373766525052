import { Schedule } from "./models";


class HTTPError extends Error {
  /**
   * Code d'erreur
   */
  code;

  /**
   * Information
   */
  data;

  /**
   * Constructeur principal
   * @param {Object} data
   * @param {number} code
   * @param {String} message
   */
  constructor(code, data, message) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

class BadRequestError extends HTTPError {
  /**
   * Constructeur principal
   * @param {Object} data
   * @param {String} message
   */
  constructor(data, message) {
    super(400, data, message);
  }
}

class ServerError extends HTTPError {
  /**
   * Constructeur principal
   * @param {Object} data
   * @param {String} message
   */
  constructor(message) {
    super(500, null, message);
  }
}

export class Client {
  _base_url;
  _creds;

  static routes = {
    horaires: {
      conseiller: {
        GET: "opening-time/conseillers",
      },
      centre_de_contact: {
        GET: "opening-time/contacts",
      },
      entreprise: {
        GET: "opening-time/entreprises",
      },
    },
    carrieres: {
      POST: "careers/create/",
    },
    leads: {
      POST: "leads/create/",
    },
    appelDeService: {
      POST: "appel-de-services/create/",
    },
    leadsEntreprise: {
      POST: "entreprise/leads/create/",
    },
    concours: {
      POST: "concours/create/"
    },
    landingPage:{
      GET: "concours/landing-page"
    }
  };

  constructor(base_url, creds) {
    this._base_url = base_url;
    this._cred = creds;
  }

  _setHeader = () => {
    //Set header
  };

  _fetch_horaire_cc = (rng = 7) => {
    const url = `${this._base_url}/${Client.routes.horaires.centre_de_contact.GET}?rng=${rng}`;
    return fetch(url, {
      method: "GET",
      headers: this._setHeader(),
    });
  };

  _fetch_horaire_conseiller = (rng = 7) => {
    const url = `${this._base_url}/${Client.routes.horaires.conseiller.GET}?rng=${rng}`;
    return fetch(url, {
      method: "GET",
      headers: this._setHeader(),
    });
  };

  _fetch_horaire_entreprise = (rng = 7) => {
    const url = `${this._base_url}/${Client.routes.horaires.entreprise.GET}?rng=${rng}`;

    return fetch(url, {
      method: "GET",
      headers: this._setHeader(),
    });
  };

  jsonToSchedule = (json) =>
    json.map(
      (data) =>
        new Schedule({
          ...data,
          minHour: data.min_hour,
          maxHour: data.max_hour,
        })
    );

  fetchSchedules = async (rng = 7) => {
    let ress = await Promise.all([
      this._fetch_horaire_cc(rng),
      this._fetch_horaire_conseiller(rng),
      this._fetch_horaire_entreprise(rng),
    ]);

    return {
      schedulesCC: this.jsonToSchedule(await ress[0].json()),
      schedulesAP: this.jsonToSchedule(await ress[1].json()),
      schedulesEntreprise: this.jsonToSchedule(await ress[2].json()),
    };
  };

  sendLead = async (formData) => {
    const url = `${this._base_url}/${Client.routes.leads.POST}`;

    let response = undefined;

    try {
      response = await fetch(url, {
        method: "POST",
        body: formData,
      });
    } catch (e) {
      return e;
    }

    if (response.status !== 201) {
      throw new Error("");
    }

    return response;
  };

  sendAppelDeService = async (formData) => {
    const url = `${this._base_url}/${Client.routes.appelDeService.POST}`;

    let response = undefined;

    try {
      response = await fetch(url, {
        method: "POST",
        body: formData,
      });
    } catch (e) {
      return e;
    }

    if (response.status !== 201) {
      throw new Error("");
    }

    return response;
  };

  sendCarriere = async (formData) => {
    const url = `${this._base_url}/${Client.routes.carrieres.POST}`;

    let response = undefined;

    try {
      response = await fetch(url, {
        method: "POST",
        body: formData,
      });
    } catch (e) {
      return e;
    }

    if (response.status !== 201) {
      throw new Error("");
    }

    return response;
  };

  sendConcours = async (formData) => {
    const url = `${this._base_url}/${Client.routes.concours.POST}`;

    let response = undefined;

    try {
      response = await fetch(url, {
        method: "POST",
        body: formData,
      });
    } catch (e) {
      throw new ServerError(
        "Une erreur inconnue s'est produite. Veuillez réessayer plus tard."
      );
    }

    if (!response.ok) {
      throw new BadRequestError(await response.json(), "Erreur 400");
    }

    return response;
  };

  getLandingPage = async () => {
    const url = `${this._base_url}/${Client.routes.landingPage.GET}`;
    console.log("URL ", url)
    return fetch(url, {
      method: "GET",
    });
  };
}

const base_url = process.env.REACT_APP_APIURL;
// const base_url = "https://api.agpassurance.ca/api"
// const base_url = "http://127.0.0.1:8000/api"
const creds = "";

export const ApiClient = new Client(base_url, creds);
