import React, { useState, useEffect } from "react";
import {SelectField} from "../Fields/SelectField"
import InputTextField from "../Fields/InputTextField";
import CheckBoxField from "../Fields/CheckBoxField";
import SelectDate from "../Fields/SelectDate";
import {MainButton, LoadingButton} from "../Buttons/Buttons";
import ConfirmationConcours from "../Banner/ConfirmationConcours";
import { ApiClient } from "../../contexts/apiContext";
import { Regx, testInput, validateDate } from "../Global/regx";
import moment from "moment";
import ModalConcours from "../Modal/modalConcours";
import Loading from "../Loading/Loading";
import ConcoursClose from "../ConcoursClose/ConcoursClose";


const ConcoursForm = () => {

    
    const [dataLandingPage, setDataLandingPage] = useState(null);
    const [loading, setLoading] = useState(true);

    async function fetchLandingPage() {
        try {
          const response = await ApiClient.getLandingPage();
          if (!response.ok) {
            throw new Error(`Erreur HTTP! Statut : ${response.status}`);
          }
          const data = await response.json();
          setDataLandingPage(data[0]);
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        } finally {
          setLoading(false);
        }
    }

    useEffect(() => {
        fetchLandingPage();
    }, []);


    const [goToPolitique, setGoToPolitique] = useState(
        <p>
            En soumettant ce formulaire, vous confirmez que vous avez lu et accepté la <a href={"/politique-de-confidentialite"} 
            style={{color: "black", fontFamily:"factor-a-regular", textUnderlineOffset:"5px"}} rel="noreferrer" target="_blank">
            Politique de confidentialité</a>. <span className="required">*</span>
        </p>
    );
 
    const [dateCollectee, setDateCollectee] = useState({  
        value: "",
        required: true,
        status: "",
        name: "dateCollectee",
        placeholder: "JJ/MM/AAAA",
        description: "",
        errorMessage: "La date n'est pas conforme, suivre le format AAAA-MM-JJ",
        type: "date",
    });
    const [firstName, setFirstName] = useState({
        value: "",
        required: true,
        status: "",
        name: "firstName",
        placeholder: "",
        description: "",
        errorMessage: "Le prénom n'est pas valide",
        type: "text",
    });
    const [lastName, setLastName] = useState({
    value: "",
    required: true,
    status: "",
    name: "lastName",
    placeholder: "",
    description: "",
    errorMessage: "Le nom n'est pas valide",
    type: "text",
    });
    const [phone, setPhone] = useState({
    value: "",
    required: true,
    status: "",
    name: "phone",
    placeholder: "",
    description: "",
    errorMessage: "Le numéro de téléphone n'est pas valide",
    type: "tel",
    });
    const [email, setEmail] = useState({
    value: "",
    required: true,
    status: "",
    name: "email",
    placeholder: "",
    description: "",
    errorMessage: "Le courriel n'est pas valide",
    type: "text",
    });
    const [insuranceType, setInsuranceType] = useState({
        value: "",
        required: true,
        status: "",
        name: "insuranceType",
        placeholder: "",
        description: "",
        errorMessage: "Vous devez choisir une option",
        type: "text",
    });
    const optionsInsuranceType = [
        { value : "", label: "Choisir", disable: true},
        { key: "1", label: "Automobile", value: "automobile" },
        { key: "2", label: "Habitation", value: "habitation" },
    ];
    const [politique, setPolitique] = useState({
        value: false,
        required: true,
        status: "",
        name: "politique",
        placeholder: "",
        description: "",
        errorMessage: "Vous devez accepter les conditions.",
        type: "checkbox",
    });
    const [buttonStatus, setButtonStatus] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [errorModal, setErrorModal] = useState({
        value: false,
        message: ""
    });
    const [success, setSuccess] = useState(false);

    

    useEffect(() => {
        let status = [firstName.status, lastName.status, phone.status, email.status, insuranceType.status, politique.status, dateCollectee.status];
        if (status.includes("error") || status.includes("")) {
          setButtonStatus(false);
        } else {
          setButtonStatus(true);
        }
        return () => {};
    }, [firstName, lastName, phone, dateCollectee, email, insuranceType, politique]);


    function submit() {
        if (buttonStatus) {
          setLoadingButton(true)
    
          const formData = new FormData();
          formData.append("first_name", firstName.value)
          formData.append("last_name", lastName.value)
          formData.append("email", email.value)
          formData.append("phone", phone.value)
          formData.append("renew_date", moment(dateCollectee.value).format("YYYY-MM-DD"))
          formData.append("renew_type", insuranceType.value)
          formData.append("concours_name", dataLandingPage? dataLandingPage.concours_name : "concours")

          ApiClient.sendConcours(formData)
            .then((res) => {
              setSuccess(true);
              setTimeout(() => {
                setLoadingButton(false)
              }, 2000);
          })
          .catch((err) => {
            switch (err.code) {
                case 400:
                    
                    if("non_field_errors" in err.data){
                        setErrorModal({ value: true, message: `L'adresse courriel ${email.value} est déjà enregistré pour se concours. Une seule participation possible.` })
                    }else{
                        setErrorModal({ value: true, message: "Veuillez corriger les champs en erreur" })
                    }
                    break;
            
                default:
                        setErrorModal({ value: true, message: "Une erreur inconnue est survenue, veuillez réessayer plus tard" })
                    break;
            }
        })
        }
    }


    if (loading) {
        return <Loading />
    }

    if(!dataLandingPage.concours_status) {
        return <ConcoursClose />
    }

    return (
    <>

        <section id="concours">

            <div className="left">

                <div className="title">
                    <h1 className="noselect">Concours</h1>
                </div>

                <div className="img-container">
                    <img className="nodrag" src="img/box.png" alt="agp assurance box logo"/>
                </div>

            </div>

            <div className="post-it noselect">
                <h2>carte-cadeau</h2>
                <h1>100$</h1>
            </div>

            {success ? (
                <ConfirmationConcours
                email={email.value}
                />
            ) : (

                <div className="right">

                    <div className="formulaire-title">
                        <h1>Formulaire</h1>
                    </div>

                    <div className="formulaire-subtitle">
                        <p>
                            Participez à notre concours et courez la chance 
                            de gagner une carte cadeau de <span style={{fontWeight: "bold"}}> {dataLandingPage.concours_prix}</span>.
                        </p>
                    </div>

                    <div className="formulaire-list">
                        <p>Pour participer au concours :</p>
                        <ul>
                            <li><p>Être résident du Québec et avoir 18 ans et plus.</p></li>
                            <li><p>Remplir le formulaire de participation.</p></li>
                        </ul>
                    </div>

                    <div className="formulaire-form">

                        <div className="double">
                            <div className="left-form">
                                <InputTextField
                                    label="Prénom"
                                    name={firstName.name}
                                    required={firstName.required}
                                    status={firstName.status}
                                    placeholder={firstName.placeholder}
                                    description={firstName.description}
                                    errorMessage={
                                    firstName.status === "error" ? firstName.errorMessage : ""
                                    }
                                    type="text"
                                    maxLength="64"
                                    onChange={(e) => {
                                    e.target.value !== ""
                                        ? testInput(e.target.value, Regx.ALPHA)
                                        ? setFirstName({
                                            ...firstName,
                                            value: e.target.value,
                                            status: "success",
                                            })
                                        : setFirstName({
                                            ...firstName,
                                            value: e.target.value,
                                            status: "error",
                                            })
                                        : setFirstName({
                                            ...firstName,
                                            value: e.target.value,
                                            status: "",
                                        });
                                    }}
                                />
                            </div>

                            <div className="right-form">
                                <InputTextField
                                    label="Nom"
                                    name={lastName.name}
                                    required={lastName.required}
                                    status={lastName.status}
                                    placeholder={lastName.placeholder}
                                    description={lastName.description}
                                    errorMessage={
                                    lastName.status === "error" ? lastName.errorMessage : ""
                                    }
                                    type="text"
                                    maxLength="64"
                                    onChange={(e) => {
                                    e.target.value !== ""
                                        ? testInput(e.target.value, Regx.ALPHA)
                                        ? setLastName({
                                            ...lastName,
                                            value: e.target.value,
                                            status: "success",
                                            })
                                        : setLastName({
                                            ...lastName,
                                            value: e.target.value,
                                            status: "error",
                                            })
                                        : setLastName({
                                            ...lastName,
                                            value: e.target.value,
                                            status: "",
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="single">
                            <InputTextField
                                label="Adresse courriel"
                                name={email.name}
                                required={email.required}
                                status={email.status}
                                placeholder={email.placeholder}
                                description={email.description}
                                errorMessage={
                                    email.status === "error" ? email.errorMessage : ""
                                }
                                type="text"
                                maxLength="320"
                                onChange={(e) => {
                                    e.target.value !== ""
                                    ? testInput(e.target.value, Regx.EMAIL)
                                        ? setEmail({
                                            ...email,
                                            value: e.target.value,
                                            status: "success",
                                        })
                                        : setEmail({
                                            ...email,
                                            value: e.target.value,
                                            status: "error",
                                        })
                                    : setEmail({
                                        ...email,
                                        value: e.target.value,
                                        status: "",
                                        });
                                }}
                            />
                        </div>

                        <div className="single">
                            <InputTextField
                                label="Téléphone"
                                name={phone.name}
                                required={phone.required}
                                status={phone.status}
                                placeholder={phone.placeholder}
                                description={phone.description}
                                errorMessage={
                                    phone.status === "error" ? phone.errorMessage : ""
                                }
                                type="tel"
                                maxLength="10"
                                onChange={(e) => {
                                    e.target.value !== ""
                                    ? testInput(e.target.value, Regx.PHONE_NUMBER)
                                        ? setPhone({
                                            ...phone,
                                            value: e.target.value,
                                            status: "success",
                                        })
                                        : setPhone({
                                            ...phone,
                                            value: e.target.value,
                                            status: "error",
                                        })
                                    : setPhone({
                                        ...phone,
                                        value: e.target.value,
                                        status: "",
                                        });
                                }}
                            />
                        </div>

                        <div className="single">
                            <SelectField 
                                label="Type d'assurance" 
                                name={insuranceType.name}
                                required={insuranceType.required}
                                status={insuranceType.status}
                                options={optionsInsuranceType}
                                placeholder={insuranceType.placeholder}
                                description={insuranceType.description}
                                errorMessage={
                                    insuranceType.status === "error" ? insuranceType.errorMessage : ""
                                }
                                onChange={(e) => {
                                    e.target.value !== ""
                                    ? setInsuranceType({
                                        ...insuranceType,
                                        value: e.target.value,
                                        status: "success",
                                        })
                                    : setInsuranceType({
                                        ...insuranceType,
                                        value: e.target.value,
                                        status: "",
                                        });
                                }}
                            />
                        </div>

                        <div className="single">
                            <SelectDate
                                label="Date de renouvellement de votre police d'assurance"
                                name={dateCollectee.name}
                                required={dateCollectee.required}
                                status={dateCollectee.status}
                                placeholder={dateCollectee.placeholder}
                                description={dateCollectee.description}
                                errorMessage={
                                    dateCollectee.status === "error" ? dateCollectee.errorMessage : ""
                                }
                                value={dateCollectee.value}
                                type={dateCollectee.type}
                                onChange={(e) => {
                                    e.target.value !== ""
                                    ? setDateCollectee({
                                        ...dateCollectee,
                                        value: e.target.value,
                                        status: "success",
                                    })
                                    : setDateCollectee({
                                        ...dateCollectee,
                                        value: e.target.value,
                                        status: "error",
                                    })
                                }}
                            />
                        </div>

                        <div className="check-zone">
                            <CheckBoxField 
                                description={goToPolitique}
                                required={politique.required}
                                errorMessage={
                                    politique.status === "error" ? politique.errorMessage : ""
                                }
                                onChange={(e) => {
                                    e.target.checked === true
                                    ? setPolitique({
                                        ...politique,
                                        value: e.target.checked,
                                        status: "success",
                                        })
                                    : setPolitique({
                                        ...politique,
                                        value: e.target.checked,
                                        status: "",
                                        });
                                }}
                            />
                        </div>

                        <div className="button-zone">

                        {loadingButton ? (
                            <LoadingButton/>
                        ) : (
                            <MainButton
                            name="PARTICIPER"
                            handle={submit}
                            activateSecondClass={true}
                            secondClassName={buttonStatus ? "main-button-inversed" : "disabled-button"}
                            />
                        )}
                            
                        </div>

                        <div className="description-zone">
                            <p>
                                * En remplissant ce formulaire, vous acceptez que AGP Assurance 
                                vous ajoute à son infolettre et vous contacte par téléphone dans 
                                les 90 jours qui précèdent votre renouvellement d'assurance 
                                automobile ou habitation. Le ou la gagnant(e) sera contacté par 
                                téléphone le {dataLandingPage.concours_end_date}. Aucun achat requis.
                            </p>
                        </div>

                    </div>
                    
                </div>

            )}

            {errorModal.value && <ModalConcours email={email.value} message={errorModal.message} />}

        </section>        
    </>
    );
};

export default ConcoursForm;