import React from "react";
import { useNavigate } from "react-router-dom";
import { MainButton } from "../Buttons/Buttons";

export default function ConcoursClose() {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <p style={styles.textTitle}>OUPS!</p>
        <p style={styles.text}>
          Malheureusement, il n'y a pas de concours en vigueur actuellement.
        </p>
        <div style={{ marginTop: 20 }}>
          <MainButton
            handle={() => navigate("/")}
            name="RETOURNER À L'ACCUEIL"
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    height: "100vh",
    backgroundColor: "#CAF456",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 15,
    marginRight: 15,
    marginTop: 18,
  },
  body: {
    width: "95%",
    maxWidth: 950,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    borderRadius: 15,
  },
  textTitle: {
    fontFamily: "factor-a-regular",
    fontSize: "clamp(24px, 5vw, 50px)",
    textAlign: "center",
  },
  text: {
    fontFamily: "factor-a-regular",
    fontSize: "clamp(16px, 4vw, 28px)",
    textAlign: "center",
    marginTop: 10,
  },
};
