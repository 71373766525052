import React from 'react';

const Loading = () => {

    const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#f3f3f3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const loaderStyle = {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #C7A0F9',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 2s linear infinite',
  };

  const spinKeyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;


  const styleTag = document.createElement('style');
  styleTag.innerHTML = spinKeyframes;
  document.head.appendChild(styleTag);

  return (
    <div style={overlayStyle}>
      <div style={loaderStyle}></div>
    </div>
  );
};

export default Loading;
